import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function SubScribeDialog({ showSnackBar, setShowSnackBar }: any) {
    return (
        <Dialog open={showSnackBar?.open} onClose={() => setShowSnackBar(null)}>
            <IconButton
                onClick={() => setShowSnackBar(null)}
                autoFocus
                sx={{ position: 'absolute', top: -5, right: 0, color: 'white' }}
            >
                <CloseIcon color="success" style={{ color: 'white' }} fontSize="small" />
            </IconButton>
            <DialogContent
                style={{ backgroundImage: `url('/modal_bg.png')` }}
                className={`text-black h-40 flex justify-center items-center min-w-[400px]`}
            >
                <div
                    className={`bg-white w-full h-full flex items-center justify-center text-xl p-5 shadow-lg flex-col`}
                >
                    <h1 className="text-center">{showSnackBar?.message}</h1>
                </div>
            </DialogContent>
        </Dialog>
    );
}
