// utils.ts
import axios from 'axios';
const getAuthToken = (): string => {
    return '';
};

const handleResponse = async (response: Response): Promise<any> => {
    const contentType = response.headers.get('content-type');

    if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        if (!response.ok) {
            throw { error: data?.error };
        }
        return data;
    } else if (contentType && contentType.includes('text')) {
        const data = await response.text();
        return data;
    } else {
        throw { error: `Unsupported content type: ${contentType}` };
    }
};

export const getAPICall = async (url: string, headers: Record<string, string> = {}): Promise<[any, any]> => {
    try {
        const token = getAuthToken();

        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                ...headers,
                'api-key': '7wpty85e739cvohb40e615b0e23e9er7',
                ...(token && { Authorization: token }),
            },
            // next: { revalidate: 0 },
            next: { revalidate: 0 },
        };
        try {
            const data = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}${url}`, requestOptions).then(
                handleResponse,
            );
            return [data, null];
        } catch (e) {
            return [null, e];
        }
    } catch (error) {
        //    console.error('API Error:', error);
        throw new Error('Something went wrong with the GET API call.');
    }
};

export const postAPICall = async (url: string, body: any, contentType: any): Promise<any> => {
    const headers = {
        'Content-Type': contentType,
        'api-key': `${process.env.NEXT_PUBLIC_API_KEY}`,
    };
    try {
        const response = await axios.post(`${process.env.NEXT_PUBLIC_API_BASE_URL}${url}`, body, { headers: headers });
        // console.log(response);
        return response;
    } catch (error) {
        console.error('Api is not working');
        // if (error.request.statusText === "Unauthorized")
        throw error;
    }
};
