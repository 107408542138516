export const drawerWidth = '80vw';
export const drawerMaxWidth = 400;

export const NAVBAR = [
    {
        id: 'events',
        name: 'Events',
        href: '#',
        target: '',
        children: {
            primary: [
                {
                    name: 'All Events',
                    href: '/events',
                    target: '_top',
                },
                {
                    name: 'Public Health Awareness',
                    href: '/events/publichealthawareness',
                    target: '_top',
                },
                {
                    name: 'FlagShip Event',
                    href: '/events/flagshipevent',
                    target: '_top',
                },
                {
                    name: 'Brand Solutions',
                    href: '/events/brandsolutions',
                    target: '_top',
                },
            ],
            secondary: [
                {
                    name: 'VOH 8th International Healthcare Conference',
                    href: "https://app.glueup.com/event/8th-international-healthcare-conference-2024-94869/",
                    target: '_top',
                },
                {
                    name: 'NATIONAL DIAGNOSTICS FORUM & AWARDS 2024',
                    href: "https://app.glueup.com/event/national-diagnostics-forum-awards-2024-86813/",
                    target: '_top',
                },
                {
                    name: 'National Diagnostics Forum 2024: Innovations & Excellence Awards',
                    href: "https://app.glueup.com/event/national-diagnostics-forum-2024-innovations-excellence-awards-92809/",
                    target: '_top',
                },
            ],
        },
    },
    {
        id: 'updates',
        name: 'Updates',
        href: '#',
        target: '',
        children: {
            primary: [
                {
                    name: 'All Updates',
                    href: '/updates',
                    target: '_top',
                },
                {
                    name: 'Idea Leader',
                    href: '/updates/idealeader',
                    target: '_top',
                },
                {
                    name: 'Brand in focus',
                    href: '/updates/brandinfocus',
                    target: '_top',
                },
                {
                    name: 'Insights',
                    href: '/updates/insights',
                    target: '_top',
                },
                {
                    name: 'News',
                    href: '/updates/news',
                    target: '_top',
                },
            ],
        },
    },
    {
        id: 'allbroadcast',
        name: 'VOH Tv',
        href: '#',
        target: '',
        children: {
            primary: [
                {
                    name: 'ALL Videos',
                    href: '/allbroadcast',
                    target: '_top',
                },
                {
                    name: 'Voice of Change',
                    href: '/allbroadcast/voiceofchange',
                    target: '_top',
                },
                {
                    name: 'Daily Broadcast',
                    href: '/allbroadcast/dailybroadcast',
                    target: '_top',
                },
                {
                    name: 'The Big idea',
                    href: '/allbroadcast/thebigidea',
                    target: '_top',
                },
            ],
        },
    },
    {
        id: 'startupincubators',
        name: 'Startup Incubator',
        href: '/startupincubators',
        target: '_top',
        // children: {
        //     primary: [
        //         {
        //             name: 'ALL Videos',
        //             href: '/startupincubators',
        //             target: '_top',
        //         },
        //     ],
        // },
    },
];
